import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FaShoppingCart, FaBars } from 'react-icons/fa'; // Importing FaBars for custom icon
import { Link } from 'react-router-dom';
import { Drawer, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ROUTE_PATHS } from '../routes';

const Header = () => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            {/* Drawer for mobile menu */}
            <Drawer title="The Print Pyramid" onClose={onClose} open={open} closable={false} extra={
                <Space>
                    <CloseOutlined onClick={onClose} style={{ fontSize: '16px', cursor: 'pointer' }} />

                </Space>
            }>
                <Nav className="flex-column">
                    <Nav.Link as={Link} to="/login">Login / Register</Nav.Link>
                </Nav>
            </Drawer>

            {/* Topbar with Contact Us, FAQ's, Track Order */}
            <div className='info-header'>
                <Container>
                    <div className="d-flex justify-content-end">
                        <Nav>
                            <Nav as={Link} to="#contact" className="top-nav-link">Contact Us</Nav>
                            <Nav as={Link} to="/faq" className="top-nav-link">FAQ's</Nav>
                            <Nav as={Link} to={ROUTE_PATHS.TERMSANDCONDITIONS} className="top-nav-link">Terms & Conditions</Nav>
                        </Nav>
                    </div>
                </Container>
            </div>

            {/* Main Navbar */}
            <Navbar expand="lg" className='appbar' style={{ backgroundColor: "#fff" }}>
                <Container>
                    <Navbar.Brand as={Link} to="/" className='app-title'>The Print Pyramid</Navbar.Brand>

                    {/* Custom button for mobile drawer */}
                    <FaBars
                        className="d-lg-none" // Show only on small screens
                        size={24}
                        color="black" // Black color for the drawer icon
                        onClick={showDrawer}
                        style={{ cursor: 'pointer' }} // Add pointer cursor to indicate clickable
                    />

                    <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-block">
                        <Nav className="ms-auto">
                            <Nav.Link as={Link} to="/login">Login / Register</Nav.Link>
                            <Nav.Link href="#cart">
                                <FaShoppingCart size={20} /> {/* Cart icon */}
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;
